import React, { useEffect, useState } from 'react';
import backgroundImage from '../images/contact.jpg';
import cotactImg from '../images/contact1.jpg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [subject, setSubject] = useState([])
    const [errorMsg, setErrorMsg] = React.useState({});
    const [sendMessage, setSendMessage] = useState({});
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    useEffect(() => {
        fetch("http://3.7.183.17:3000/api/website/subject/details")
            .then((response) => {
                if (!response.ok) {
                    console.log('Network response was not ok');
                }
                return response.json();
            })
            .then((data) => {
                const subject = data.data.map((item) => ({
                    label: item,
                    value: item
                }));
                setSubject(subject)
            })
            .catch((error) => {
                console.log(error)
            });
    }, [])

    const handleChange = (e) => {
        const { id, value } = e.target;

        if (id === "fullName") {
            setErrorMsg((errorMsg) => ({ ...errorMsg, fullNameError: false }));
            setSendMessage({ ...sendMessage, [id]: value });
        }

        if (id === "email") {
            setErrorMsg((errorMsg) => ({ ...errorMsg, emailError: false, emailValidError: false }));
            setSendMessage({ ...sendMessage, [id]: value });
        }

        if (id === "subject") {
            setErrorMsg((errorMsg) => ({ ...errorMsg, subjectError: false }));
            setSendMessage({ ...sendMessage, [id]: value });
        }

        if (id === "data") {
            setErrorMsg((errorMsg) => ({ ...errorMsg, dataError: false, dataValidError: false }));
            setSendMessage({ ...sendMessage, [id]: value });
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        validate();
    };

    const validate = async () => {
        if (!sendMessage.fullName) {
            setErrorMsg((errorMsg) => ({ ...errorMsg, fullNameError: true }));
            return;
        }

        if (!sendMessage.email) {
            setErrorMsg((errorMsg) => ({ ...errorMsg, emailError: true }));
            return;
        }

        if (
            sendMessage.email &&
            !emailRegex.test(sendMessage.email.toLowerCase())
        ) {
            setErrorMsg((errorMsg) => ({ ...errorMsg, emailValidError: true }));
            return;
        }

        if (!sendMessage.subject) {
            setErrorMsg((errorMsg) => ({ ...errorMsg, subjectError: true }));
            return;
        }

        if (!sendMessage.data) {
            setErrorMsg((errorMsg) => ({ ...errorMsg, dataError: true }));
            return;
        }

        if (sendMessage.data && sendMessage.data.length >= 100) {
            setErrorMsg((errorMsg) => ({ ...errorMsg, dataValidError: true }));
            return;
        }

        try {
            const response = await fetch('http://3.7.183.17:3000/api/website/contactForm/mail/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(sendMessage),
            });

            if (!response.ok) {
                toast.error('Mail not sent. Please check the form and try again.', {
                    position: "bottom-center",
                    autoClose: 3000,
                });
            }

            toast.success('Mail sent successfully!', {
                position: "bottom-center",
                autoClose: 3000,
            });
        } catch (error) {
            toast.error('Mail not sent. Please try again later.', {
                position: "bottom-center",
                autoClose: 3000,
            });
        }
    };

    return (
        <>
            <section className="hero-wrap hero-wrap-2 position-relative" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', backgroundPosition: 'center' }} data-stellar-background-ratio="0.5">
                <div className="overlay"></div>
                <div className="position-absolute text-overlay d-flex align-items-center justify-content-center w-100 h-100">
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-center">
                            <div className="col-md-12 text-center">
                                <h1 className="mb-0 bread">Contact</h1>
                                <h3 className="text-white">Feel free to reach out to us for any inquiries or questions you may have.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section bg-light">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-6 text-center mb-5">
                            <h2 className="heading-section">Contact</h2>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="wrapper">
                                <div className="row mb-5">
                                    <div className="col-md-6">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-map-marker"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Address:</span> #2768, Halladakeri,
                                                    Mahaveer Nagar,
                                                    Mysore - 570001</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-phone"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Phone:</span> <a href="tel://1234567920">+ 1235 2355 98</a></p>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-paper-plane"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Email:</span> <a href="mailto:contactus@smartpetmart.in">contactus@smartpetmart.in</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-4">
                                        <div className="dbox w-100 text-center">
                                            <div className="icon d-flex align-items-center justify-content-center">
                                                <span className="fa fa-globe"></span>
                                            </div>
                                            <div className="text">
                                                <p><span>Website</span> <a href="#">yoursite.com</a></p>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row no-gutters">
                                    <div className="col-md-7">
                                        <div className="contact-wrap w-100 p-md-5 p-4">
                                            <h3 className="mb-4">Contact Us</h3>
                                            <form onSubmit={handleSubmit} className="contactForm">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="name">Full Name</label>
                                                            <input type="text" className="form-control" name="name" id="fullName" placeholder="Enter your name" onChange={handleChange} />
                                                            {errorMsg && errorMsg.fullNameError && (
                                                                <span style={{ color: "red" }}>Name is required</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="email">Email Address</label>
                                                            <input type="email" className="form-control" name="email" id="email" placeholder="Enter your email" onChange={handleChange} />
                                                            {errorMsg && errorMsg.emailError && (
                                                                <span style={{ color: "red" }}>Email is required</span>
                                                            )}
                                                            {errorMsg && errorMsg.emailValidError && (
                                                                <span style={{ color: "red" }}>Email is invalid</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="subject">Subject</label>
                                                            <select className="form-control" name="subject" id="subject" onChange={handleChange}>
                                                                <option value="">Select a Subject</option>
                                                                {subject.map((data, i) => (<option value={data.value} key={i}>{data.label}</option>))}
                                                            </select>
                                                            {errorMsg && errorMsg.subjectError && (
                                                                <span style={{ color: "red" }}>Select a subject</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" for="#">Message</label>
                                                            <textarea name="message" className="form-control" id="data" cols="30" maxLength="100"
                                                                rows="4" placeholder="Enter your message Max 100 words" onChange={handleChange}></textarea>
                                                            {errorMsg && errorMsg.dataError && (
                                                                <span style={{ color: "red" }}>Message is required</span>
                                                            )}
                                                            {errorMsg && errorMsg.dataValidError && (
                                                                <span style={{ color: "red" }}>Message must be 100 characters or less</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <button type="submit" className="btn btn-primary">Send Message</button>
                                                            <div className="submitting"></div>
                                                        </div>
                                                    </div>
                                                    {/* Toaster Message */}
                                                    <ToastContainer />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-5 d-flex align-items-stretch">
                                        <div className="info-wrap w-100 p-5 img" style={{ backgroundImage: `url(${cotactImg})` }}>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d3898.0694910697252!2d76.6544130741096!3d12.31110712901677!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3baf70130a5b8595%3A0x75d2aef255ba1d9c!2s2768%2C%20Halladakeri%20Main%20Rd%2C%20Lashkar%20Mohalla%2C%20Mandi%20Mohalla%2C%20Mysuru%2C%20Karnataka%20570001!3m2!1d12.311101899999999!2d76.656988!5e0!3m2!1sen!2sin!4v1727008455961!5m2!1sen!2sin" width="600" height="450" style={{ border: 0, width: "100%" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </>
    );
};

export default Contact;
